import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';
import LoyaltyPointsWidget from '../loyalty/loyaltyPointsWidget';
import UtilityList from './utilityList';
import SimpleModal from '../modal/modal';
import TransferToggle from '../account/transferToggle';
import TransferNFT from '../account/transferNFT';
import TransferWallet from '../account/transferWallet';
import UtilityManager from '../../managers/Utility';
import CandidSoulFAQ from '../loyalty/candidSoulFAQ';

import { isMobile, getParameterByName } from '../../managers/Helpers';

function MembershipDetails() {
  // State variables using useState
  // const [openNav, setNavstate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [asset, setAsset] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [showPassModal, setShowPassModal] = useState(false);
  const [isPassGenerating, setIsPassGenerating] = useState(true);
  const [nftPass, setNftPass] = useState(null);
  const [openNav, setNavstate] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [transferSelector, toggleTransferSelectorModal] = useState(false);
  const [transfer, setUserTransferModal] = useState(false);
  const [walletTransfer, setWalletTransferModal] = useState(false);
  const [sortedUtility, setSortedUtility] = useState([]);
  const [membershipFeatures, setMembershipFeatures] = useState([]);
  const [shouldOpenShareModal, setShouldOpenShareModal] = useState(false);
  const [shouldOpenShopModal, setShouldOpenShopModal] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [previewWarningModal, setPreviewWarningModal] = useState(false);

  const { userAssetPointsId } = useParams();

  const parseUtility = (asset) => {
    // const allUtility = asset.utility.concat(asset.nftUtility);
    const allUtility = asset.utility;
    const categorizedUtility = UtilityManager.categorizeAssetUtility(allUtility);
    setSortedUtility(categorizedUtility);
  };

  const getDetails = async (userAssetPointsId) => {
    setIsLoading(true);
    const availableFeatures = [];
    const accountData = await AccountManager.get(AccountManager.getToken());
    if (accountData) {
      setAccountDetails(accountData);
    }
    AccountManager.getMembershipDetails(AccountManager.getToken(), userAssetPointsId).then((res) => {
      setAsset(res.account ? res.account : res.accountContract);
      if (res.membershipFeatures && res.membershipFeatures.length > 0) {
        res.membershipFeatures.forEach((feature) => {
          if ((!feature.tierId || feature.tierId === res.account.assetVariation.assetVariationId) && feature.isActive) {
            availableFeatures.push(feature);
          }
        });
        setMembershipFeatures(availableFeatures);
      }
      if (res.account ? res.account.utility : res.accountContract.utility) {
        parseUtility(res.account ? res.account : res.accountContract);
      }
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
      toast.error('Oops. There was a problem getting your membership data. Please try again later.');
    });
  };

  const getPreviewDetails = async (membershipId, tierId, sessionId) => {
    setIsLoading(true);
    const availableFeatures = [];
    AccountManager.getPreviewDetails(membershipId, tierId, sessionId).then((res) => {
      setAsset(res.account ? res.account : res.accountContract);
      if (res.membershipFeatures && res.membershipFeatures.length > 0) {
        res.membershipFeatures.forEach((feature) => {
          if ((!feature.tierId || feature.tierId === res.account.assetVariation.assetVariationId) && feature.isActive) {
            availableFeatures.push(feature);
          }
        });
        setMembershipFeatures(availableFeatures);
      }
      if (res.account ? res.account.utility : res.accountContract.utility) {
        parseUtility(res.account ? res.account : res.accountContract);
      }
      setIsLoading(false);
    }).catch((error) => {
      setIsLoading(false);
      toast.error('Preview url has expired. Please try again with a fresh preview url.');
    });
  };

  const toggleTransferModal = (showModal, nft) => {
    setNavstate(false);
    toggleTransferSelectorModal(showModal);
    setSelectedFile(asset);
  };

  const showUserTransferModal = () => {
    toggleTransferSelectorModal(false);
    setUserTransferModal(asset);
  };

  const showWalletTransferModal = () => {
    toggleTransferSelectorModal(false);
    setWalletTransferModal(asset);
  };

  const closeWalletTransferModal = (doUpdate) => {
    setWalletTransferModal(false);
    if (doUpdate) {
      // getCollection();
    }
  };

  const transferSuccessful = (walletAddress) => {
    console.log(walletAddress);
    console.log('NFT transferred successfully');
    nft.withdrawAddress = walletAddress;
  };

  const confirmTransfer = () => {
    asset.hasPendingTransfer = true;
    setUserTransferModal(false);
  };

  const cancelTransfer = (newItem) => {
    setShowModal(true);
  };

  const togglePassModal = async (nft) => {
    setShowPassModal(!showPassModal);
    setIsPassGenerating(true);
    const payload = {
      nftId: nft.nftId,
    };
    const applePass = await AccountManager.generateApplePass(AccountManager.getToken(), payload).then((response) => {
      console.log('=== generating apple pass ===');
      console.log(response);
      if (response && response.success) {
        toast.success('Successfully created Apple pass.');
        setNftPass(response.passUri);
      } else {
        toast.error('Error creating Apple pass. Please try again later.');
        setShowPassModal(false);
      }
      setIsPassGenerating(false);
    });
  };

  const toggleNav = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (openNav) {
      setNavstate(false);
    } else {
      setNavstate(true);
    }
  };

  const closeAllModels = () => {
    setShouldOpenShareModal(false);
    setShouldOpenShopModal(false);
  };

  const openReferModal = () => {
    if (previewMode) {
      setPreviewWarningModal(true);
    } else {
      setShouldOpenShareModal(true);
    }
  };

  const openShareModal = () => {
    if (previewMode) {
      setPreviewWarningModal(true);
    } else {
      setShouldOpenShopModal(true);
    }
  };

  useEffect(() => {
    if (getParameterByName('sessionId')
      && getParameterByName('membershipId')
      && getParameterByName('tierId')) {
      setPreviewMode(true);
      getPreviewDetails(getParameterByName('membershipId'), getParameterByName('tierId'), getParameterByName('sessionId'));
    } else if (AccountManager.isLoggedIn()) {
      getDetails(userAssetPointsId);
    } else {
      window.location = '/';
    }
  }, []);

  return (
    <div className="digital-asset-details">
      {walletTransfer
      && (
        <div>
          <TransferWallet
            nft={walletTransfer}
            onClose={(doUpdate) => closeWalletTransferModal(doUpdate)}
            onSuccess={(walletAddress) => transferSuccessful(walletAddress)} />
        </div>
      )}
      {transfer
        && (
        <div>
          <TransferNFT nft={transfer} onClose={() => setUserTransferModal(false)} onSuccess={() => confirmTransfer()} />
        </div>
        )}
      {transferSelector
        && (
        <div>
          <TransferToggle
            onTransferToUser={() => showUserTransferModal()}
            onTransferToWallet={() => showWalletTransferModal()}
            onClose={() => toggleTransferSelectorModal(false)} />
        </div>
        )}

      <SimpleModal isOpen={previewWarningModal} onBeforeClose={() => setPreviewWarningModal(false)}>
        This feature is not available in preview mode.
        <div className="form-cta">
          <button
            className="button button-confirm -no-margin"
            type="button"
            onClick={() => setPreviewWarningModal(false)}>
            Close
          </button>
        </div>
      </SimpleModal>

      <SimpleModal isOpen={showPassModal} onBeforeClose={() => setShowPassModal(false)}>
        {showPassModal && (
        <div>
          {isPassGenerating
              && <Throbber throbberText="Generating Apple Pass! Please stand by..." />}
          <div className="transfer-success register-results">
            <img className="register-results__icon" src="https://ipfs.ethosnft.com/ethos/icon-success.svg" alt="" />
            <div className="register-results__header">Success!</div>
            <p>
              Apple pass for your membership has been created. Click the Add to wallet button below to add this to your wallet.
            </p>
          </div>
          <div className="form-cta -align-center">
            <a href={nftPass} className="button button-apple-wallet">
              <img src="http://ipfs.ethosnft.com/ethos/button-apple-wallet.png" alt="Add to Apple Wallet" />
            </a>
          </div>
        </div>
        )}
      </SimpleModal>
      {isLoading
        && <Throbber throbberText="Loading membership details! Please stand by..." />}
      {asset
      && (
        <>
          <div className="collection-breadcrumb">
            My Membership&nbsp;&nbsp;&bull;&nbsp;&nbsp;
            {/* <Link to="/account/collection">{asset.creator.creatorName}</Link>
                &nbsp;&nbsp;&bull;&nbsp;&nbsp; */}
            <Link to={'/membership/' + userAssetPointsId}>{asset.asset.name}</Link>
          </div>
          <h1 className="digital-asset__header">
            {asset.asset.name}
            <div className="digital-asset__header-utils">
              {asset.passTemplate
              && <button type="button" className="button-collection" onClick={() => togglePassModal(asset)}>Add to Wallet</button>}
              {/* &nbsp;
              <button onClick={(e) => toggleNav(e)} type="button" className={openNav ? 'button-collection__inverse open' : 'button-collection__inverse'}>&#x22EF;</button>
              <div className={openNav ? 'dropdown-widget__menu -asset-details open' : 'dropdown-widget__menu -asset-details'}>
                {AccountManager.isVerified()
                && (
                  <div className="dropdown-widget__menu--item">
                    {env.DIGITAL_ASSET_TYPE
                      ? (
                        <a onClick={() => toggleTransferModal(true)} href="#">
                          Transfer Membership
                        </a>
                      )
                      : <a onClick={() => toggleTransferModal(true)} href="#">Transfer Digital Asset</a>}
                  </div>
                )}
                <div className="dropdown-widget__menu--item">
                  <a href={getScanUrl(asset)} target="_blank" onClick={() => setNavstate(false)} rel="noreferrer">View on Blockchain</a>
                </div>
              </div> */}
            </div>
          </h1>
          <div className={asset.assetVariation.mediaFileUrl.includes('.mp4') ? 'digital-asset__media' : 'digital-asset__media -aspect-ratio'}>
            {/* <div className="digital-assets__tile--banner">
              <img src="https://assets.heyethos.com/bonlook/landing/bonlook_banner2.jpg" alt="Membership" />
            </div> */}
            <div className="digital-assets__tile--image-holder">
              {asset.assetVariation.mediaFileUrl.includes('.mp4') ? (
                <video autoPlay="autoplay" playsInline loop muted poster={asset.assetVariation.thumbnailUrl}>
                  <source src={asset.assetVariation.mediaFileUrl} type="video/mp4" />
                  <track
                      default
                      kind="captions"
                      srcLang="en"
                      src="" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={asset.assetVariation.mediaFileUrl} alt="Membership" />
              )}
            </div>
          </div>
          
          <div id="benefitsPanel" className="digital-asset__details">

            <LoyaltyPointsWidget openShopReferralModal={shouldOpenShopModal} openReferModal={shouldOpenShareModal} onModalClose={() => closeAllModels()} loyaltyAccountId={userAssetPointsId} creatorId={process.env.CREATOR_ID} points={asset.points} tier={asset.assetVariation} assetId={process.env.ASSET_ID} features={membershipFeatures} onPreviewCTA={() => setPreviewWarningModal(true)} isPreviewMode={previewMode} />
            <div>

              <div className="digital-asset__section">
                <h2 className="digital-asset__subheader">Description</h2>

                {asset.assetVariation.variationName === 'Insider' && (
                <div className="digital-asset__details--text">
                  As an Insider, you’re joining the heart of the Candid Soul Tribe! You get access to our newsletter, exclusive content, and opportunities to earn rewards just by being part of the community. It’s all about keeping you inspired and connected as you walk your journey of faith and growth.
                </div>
                )}

                {asset.assetVariation.variationName === 'Seeker' && (
                <div className="digital-asset__details--text">
                  As a Seeker, you’re committed to deepening your connection with yourself and God. You’ll enjoy exclusive content, deeper discounts, and priority access to events and workshops, empowering you to continue your path of growth and discovery.
                </div>
                )}

                {asset.assetVariation.variationName === 'Believer' && (
                <div className="digital-asset__details--text">
                  As a Believer, you’re fully invested in your journey and growth. You gain access to premium perks like free shipping, birthday gifts, and our highest tier discounts. Dive deeper into personal and spiritual development with exclusive resources and events.
                </div>
                )}

                {/* {asset.asset.assetDescription !== 'NULL'
                && (
                <div className="digital-asset__details--text">
                  {asset.asset.assetDescription}
                </div>
                )}
                <div
                  className="digital-asset__details--text"
                  dangerouslySetInnerHTML={{ __html: asset.asset.assetHTMLDesc }}>
                </div> */}
              </div>
              
              {/* <div className="digital-asset__section">
                <h2 className="digital-asset__subheader">Benefits</h2>
                
                <div className="benefit-summary">
                  <div className="benefit-summary__item">
                    <img className="benefit-summary__item--icon" src="https://assets.heyethos.com/ethos/v2/ui/icon-shipping.png" alt="Free shipping" />
                    <div className="benefit-summary__item--heading">Free shipping on all orders.</div>
                    <div className="benefit-summary__item--description">Shop online with ease and enjoy free shipping on all your orders!</div>
                  </div>
                  <div className="benefit-summary__item">
                    <img className="benefit-summary__item--icon" src="https://assets.heyethos.com/ethos/v2/ui/icon-invites.png" alt="Special invites" />
                    <div className="benefit-summary__item--heading">Special invites.</div>
                    <div className="benefit-summary__item--description">You’ll get invites to Trendsetter events. </div>
                  </div>
                  <div className="benefit-summary__item">
                    <img className="benefit-summary__item--icon" src="https://assets.heyethos.com/ethos/v2/ui/icon-contests.png" alt="Exclusive contests" />
                    <div className="benefit-summary__item--heading">Access to exclusive contests.</div>
                    <div className="benefit-summary__item--description">As members of our Trendsetter Rewards Program, you’re getting access to contests and perks that no one else does.</div>
                  </div>
                </div>
              </div> */}
              {sortedUtility.length > 0
              && (
              <div>
                {sortedUtility.map((utility) => {
                  if (utility.label !== (process.env.CUSTOM_MEDIA_TITLE || 'Media')) {
                    return (
                      <div className="utility-section" key={utility.label}>
                        <h2 className="digital-asset__subheader">{utility.label}</h2>
                        <UtilityList
                          noHeader
                          nftId={asset.nftId}
                          assetUtility={[]}
                          collectionUtility={utility.utility}
                          onPreviewCTA={() => setPreviewWarningModal(true)}
                          isPreviewMode={previewMode} />
                      </div>
                    );
                  }
                  return <div key={utility.label}></div>;
                })}
              </div>
              )}
              {sortedUtility.length > 0
              && (
              <div>
                {sortedUtility.map((utility) => (
                  <div key={utility.label}>
                    {utility.label === (process.env.CUSTOM_MEDIA_TITLE || 'Media')
                    && (
                    <div className="utility-section">
                      <h2 className="digital-asset__subheader -extra-padding">{utility.label}</h2>
                      <UtilityList
                        slideshow
                        noHeader
                        nftId={asset.nftId}
                        assetUtility={[]}
                        collectionUtility={utility.utility}
                        onPreviewCTA={() => setPreviewWarningModal(true)}
                        isPreviewMode={previewMode} />
                    </div>
                    )}
                  </div>
                ))}
              </div>
              )}
              <div>

              </div>
            </div>
          </div>
          <div className="digital-asset__background">
            <CandidSoulFAQ noHeader />
          </div>
        </>
      )}

    </div>
  );
}

export default MembershipDetails;
